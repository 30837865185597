<script>
// import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
/**
 * page-recruiter-directory component
 */
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { initFbsdk } from "@/core/config/facebook.js";
import { API_URL, MEDIA_URL } from "@/core/api";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import axios from "axios";
// import { send } from 'process';
export default {
  data() {
    return {
      auth: localStorage.getItem("id_token") !== null ? true : false,
      token: `Bearer ${localStorage.getItem("token")}`,
      mediaUrl: MEDIA_URL,
      sent: false,
      ContactForm: {
        name_mail: "",
        email_mail: "",
        msg_mail: "",
      },
      recruiterSignUp: false,
      agencySignUp: false,
      state: "signin",
      form: {
        email: "",
        password: "",
      },
      cities: [],
      apiUrl: API_URL,
      search_text: "",
      selected_cities: [],
      novalidate: "",
    };
  },
  components: {
    // Navbar,
    Switcher,
    Footer,
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },
  mounted() {
    if (window.location.href.indexOf("register") !== -1) {
      this.showForm("signup");
    }

    const signup_form = KTUtil.getById("kt_login_signup_form");

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Full name is required",
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required",
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "Passwords do not match",
            },
          },
        },
        agree: {
          validators: {
            notEmpty: {
              message: "Please accept terms and conditions",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
  },
  methods: {
    showForm(form) {
      console.log("test");
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name)
        // "animate__animated animate__backInUp"
      );
    },
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },
    onSubmitRegister() {
      // this.fv1.on("core.form.valid", () => {
      this.fv1.validate();
      this.fv1.validate().then((res) => {
        if (res == "Valid") {
          const firstName = this.$refs.firstName.value;
          const lastName = this.$refs.lastName.value;
          const email = this.$refs.remail.value;
          const password = this.$refs.rpassword.value;
          // const compnay_name = this.$refs.compnay_name.value;
          const compnay_name =
            this.recruiterSignUp == true || this.agencySignUp == true
              ? this.$refs.compnay_name.value
              : null;

          // clear existing errors
          this.$store.dispatch(LOGOUT);

          // set spinner to submit button
          const submitButton = this.$refs["kt_login_signup_submit"];
          submitButton.classList.add(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          setTimeout(() => {
            // send register request
            console.log("Register page log");
            this.$store.dispatch(REGISTER, {
              email: email,
              password: password,
              name: firstName + " " + lastName,
              compnay_name: compnay_name,
              role_id:
                this.recruiterSignUp == true
                  ? "2"
                  : this.agencySignUp == true
                  ? "6"
                  : "1",
            });

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          }, 2000);
        } else {
          Swal.fire({
            title: "",
            text: "Please, provide correct data!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }
      });
    },

    AuthProvider(provider) {
      var self = this;

      this.$auth
        .authenticate(provider)
        .then((response) => {
          self.SocialLogin(provider, response);
        })
        .catch((err) => {
          // console.log({ err: err });
        });
    },
    sendForm(e) {
      e.preventDefault();
      const token = this.token;
      const querystring = require("querystring");
      console.log(this.ContactForm);
      axios.defaults.headers.common["Authorization"] =
        "Bearer 1466|CLioAG8rAmJ4MmviWKK1zYaR2FMxCFvVmtQWQuqsPnIYngGTYDP5nJnrsaeZCiIQu9MgdQR69khE69rl";
      axios
        .post(
          `${this.apiUrl}/email-resive-msg`,
          querystring.stringify(this.ContactForm)
        )
        .then((res) => {
          this.sent = true;
        })
        .then((res) => {
          Swal.fire({
            title: "",
            text: "Your question has been submitted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
  },
};
</script>

<template>
  <div>
    <!-- <Navbar /> -->
    <!-- Navbar STart -->
    <header
      id="topnav"
      class="defaultscroll sticky"
      :class="{ 'bg-white': isWhiteNavbar === true }"
    >
      <div class="container">
        <!-- Logo container-->
        <div>
          <router-link class="logo" to="/" v-if="navLight !== true">
            <img src="/images/logo-dark.svg" height="50" alt="" />
          </router-link>
          <router-link class="logo" to="/" v-else>
            <img
              src="/images/logo-dark.svg"
              class="l-dark"
              height="50"
              alt=""
            />
            <img
              src="/images/logo-dark.svg"
              class="l-light"
              height="24"
              alt=""
            />
          </router-link>
        </div>
        <div class="buy-button" style="width: 28% text-align: end;">
          <a
            v-if="auth"
            class="btn auth_btn_ch2 dropdown"
            :class="{
              'btn-light': navLight === true,
              'btn-primary': navLight !== true,
            }"
            >My Account
            <div class="dropdown-content">
              <router-link
                to="/dashboard"
                class="btn auth_btn_ch3"
                style="
                  margin-left: 3%;
                  background: transparent !important;
                  color: #000000 !important;
                "
                >Dashboard</router-link
              >
              <a
                @click="onLogout"
                class="btn auth_btn_ch3"
                style="
                  margin-left: 3%;
                  background: transparent !important;
                  color: #000000 !important;
                "
                >Logout</a
              >
            </div>
          </a>
          <a
            v-if="!auth"
            href="/login"
            class="btn auth_btn_ch1"
            :class="{
              'btn-light': navLight === true,
              'btn-primary': navLight !== true,
            }"
            >Login</a
          >
          <a
            v-if="!auth"
            href="/recruiter-registeration"
            class="btn auth_btn_ch2"
            :class="{
              'btn-light': navLight === true,
              'btn-warning': navLight !== true,
            }"
            style="margin-left: 3%"
            >Register</a
          >
          <!-- <a
            v-if="!auth || recruiter"
            href="/recruit-top-talent-in-pakistan"
            class="btn auth_btn_ch3"
            style="margin-left: 3%; background: #a9250f; color: white"
            >Recruiter &nbsp;&nbsp;<i
              class="fa fa-caret-right"
              style="color: white"
            ></i
          ></a> -->

          <a
            href="/"
            class="btn auth_btn_ch4"
            style="margin-left: 3%; background: #a9250f; color: white"
          >
            Job Seekers &nbsp;&nbsp;<i
              class="fa fa-caret-right"
              style="color: white"
            ></i
          ></a>
        </div>

        <!--end login button-->
        <!-- End Logo container-->
        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a
              class="navbar-toggle"
              @click="toggleMenu()"
              :class="{ open: isCondensed === true }"
            >
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </div>
        </div>

        <div id="navigation">
          <!-- Navigation Menu-->
          <ul
            class="navigation-menu"
            :class="{ 'nav-light': navLight === true }"
          >
            <li>
              <router-link to="/" class="side-nav-link-ref">Home</router-link>
            </li>
            <li>
              <router-link to="/jobs-in-pakistan" class="side-nav-link-ref"
                >Jobs</router-link
              >
            </li>

            <li>
              <router-link to="/career-resources" class="side-nav-link-ref"
                >Career Resources</router-link
              >
            </li>
            <div class="ml-10 mb-10 hide_on_dectop_ch">
              <a
                v-if="auth"
                class="btn auth_btn_ch2 dropdown"
                :class="{
                  'btn-light': navLight === true,
                  'btn-primary': navLight !== true,
                }"
                >My Account
                <div class="dropdown-content">
                  <router-link
                    to="/dashboard"
                    class="btn auth_btn_ch3"
                    style="
                      margin-left: 3%;
                      background: transparent !important;
                      color: #000000 !important;
                    "
                    >Dashboard</router-link
                  >
                  <a
                    @click="onLogout"
                    class="btn auth_btn_ch3"
                    style="
                      margin-left: 3%;
                      background: transparent !important;
                      color: #000000 !important;
                    "
                    >Logout</a
                  >
                </div>
              </a>
              <a
                v-if="!auth"
                href="/login"
                class="btn auth_btn_ch1"
                :class="{
                  'btn-light': navLight === true,
                  'btn-primary': navLight !== true,
                }"
                >Login</a
              >
              <a
                v-if="!auth"
                href="/recruiter-registeration"
                class="btn auth_btn_ch2"
                :class="{
                  'btn-light': navLight === true,
                  'btn-warning': navLight !== true,
                }"
                style="margin-left: 3%"
                >Register</a
              >

              <a
                href="/"
                class="btn auth_btn_ch4"
                style="margin-left: 3%; background: #a9250f; color: white"
              >
                Job Seekers &nbsp;&nbsp;<i
                  class="fa fa-caret-right"
                  style="color: white"
                ></i
              ></a>
            </div>
          </ul>
        </div>
        <!--end navigation-->
      </div>
      <!--end container-->
    </header>
    <!--end header-->
    <!-- Hero Start -->
    <section class="section_ch_p bg-light d-table w-100 mt-5">
      <div class="bg_image_rec">
        <div class="container py-10" style="margin: 0 auto">
          <div class="row">
            <div class="col-lg-5"></div>
            <div class="col-lg-7 rec_col_bg_img">
              <div class="rec_hero_h">
                find <br />
                <span
                  >Talented.<br />
                  Energetic.</span
                ><br />
                minds to help<br />
                your business <span>grow!</span>
              </div>
              <button
                class="card-headerch_btn bg_blue_ch mt-4 px-3 py-2"
                style="width: fit-content !important"
              >
                <a class="text-white" href="/recruiter-registeration">
                  GET STARTED
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-light">
        <div class="container pt-10" style="margin: 0 auto">
          <div class="row">
            <div class="col-lg-12 pk_bg_img2 text-center">
              <h2>End-to-end <span>process</span> coverage</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white">
        <div class="container py-8 mobile_bg_icon_ch" style="margin: 0 auto">
          <div class="row">
            <div class="col-lg-3 col-3 text-center">
              <img src="/images/rec/Seniorlevelhiring.png" alt="..." />
              <div class="mt-3 font-weight-bold" style="font-size: 20px">
                Senior level hiring
              </div>
            </div>
            <div class="col-lg-3 col-3 text-center">
              <img src="/images/rec/adjustpostvisibility.png" alt="..." />
              <div class="mt-3 font-weight-bold" style="font-size: 20px">
                Adjust your post visibility
              </div>
            </div>
            <div class="col-lg-3 col-3 text-center">
              <img src="/images/rec/verifiedtalent.png" alt="..." />
              <div class="mt-3 font-weight-bold" style="font-size: 20px">
                Verified & best talent
              </div>
            </div>
            <div class="col-lg-3 col-3 text-center">
              <img src="/images/rec/quickpostandconnect.png" alt="..." />
              <div class="mt-3 font-weight-bold" style="font-size: 20px">
                Quickly post & connect directly
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-light" style="position: relative">
        <div class="container py-15" style="margin: 0 auto">
          <div class="row">
            <div class="get_by_ch col-lg-12 pk_bg_img3 text-center">
              <h2>Get <span>Flexible. Affordable.</span>solutions</h2>
            </div>
            <div class="col-lg-12 row text-center all_pln_ch">
              <div class="col-lg-4">
                <div class="fst_plan">
                  <h2 class="bacic_primery_by_ch">BASIC</h2>
                  <div class="list_price pb-1">1 MONTH FREE</div>
                  <p style="font-size: 12px" class="mb-0">Limited time offer</p>
                  <p style="font-size: 12px">No billing information required</p>
                  <div class="rec_list">
                    <img src="/images/rec/Available_.png" alt="..." /> Limited
                    time offer
                  </div>
                  <div class="rec_list2">
                    <img src="/images/rec/Available_.png" alt="..." />Two months
                    validity
                  </div>
                  <div class="rec_list">
                    <img src="/images/rec/Available_.png" alt="..." />Try before
                    you buy
                  </div>
                  <div class="rec_list2">
                    <img
                      src="/images/rec/Not_available.png"
                      alt="..."
                    />Featured job
                  </div>
                  <div class="rec_list">
                    <img
                      src="/images/rec/Not_available.png"
                      alt="..."
                    />Featured employer
                  </div>
                  <button class="join_btn2 mt-5 LETSGO" style="width: 80%">
                    <a href="/recruiter-registeration" class="text-white">
                      LET’S GO
                    </a>
                  </button>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="scnd_plan">
                  <h2 class="bacic_primery_by_ch mt-3">STANDARD</h2>
                  <span class="list_price pt-5">1 MONTH FREE</span>
                  <!-- //19999 -->
                  <p style="font-size: 12px" class="pb-2 pt-3">
                    Limited time offer
                  </p>
                  <p style="font-size: 12px">No billing information required</p>
                  <div class="rec_list">
                    <img src="/images/rec/Available_.png" alt="..." />
                    Everything in BASIC
                  </div>
                  <div class="rec_list2">
                    <img src="/images/rec/Available_.png" alt="..." />Featured
                    job
                  </div>
                  <div class="rec_list">
                    <img src="/images/rec/Available_.png" alt="..." />Three
                    months validity
                  </div>
                  <div class="rec_list2">
                    <img src="/images/rec/Available_.png" alt="..." />Limited
                    time offer
                  </div>
                  <div class="rec_list">
                    <img
                      src="/images/rec/Not_available.png"
                      alt="..."
                    />Featured employer
                  </div>
                  <button class="join_btn2 mt-4" style="width: 80%">
                    <a href="/recruiter-registeration" class="text-white">
                      LET’S GO
                    </a>
                  </button>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="trd_plan">
                  <h2 class="bacic_primery_by_ch">PREMIUM</h2>
                  <span class="list_price">1 MONTH FREE </span>
                  <p style="font-size: 12px" class="mb-0">Limited time offer</p>
                  <p style="font-size: 12px">No billing information required</p>
                  <div class="rec_list">
                    <img src="/images/rec/Available_.png" alt="..." />
                    Everything in STANDARD
                  </div>
                  <div class="rec_list2">
                    <img src="/images/rec/Available_.png" alt="..." />Six months
                    validity
                  </div>
                  <div class="rec_list">
                    <img src="/images/rec/Available_.png" alt="..." />Featured
                    employer
                  </div>
                  <div class="rec_list2">
                    <img src="/images/rec/Available_.png" alt="..." />Priority
                    customer support
                  </div>
                  <div class="rec_list">
                    <img src="/images/rec/Available_.png" alt="..." />Social
                    media exposure
                  </div>
                  <button class="join_btn2 mt-5 LETSGO2" style="width: 80%">
                    <a href="/recruiter-registeration" class="text-white">
                      LET’S GO
                    </a>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-12 pk_bg_img3 text-center pt-12 still_by_ch">
              <h2>Still <span>confused?</span></h2>
            </div>
            <p class="plan_p_style">
              Contact us and we will be happy to assist you. Our office hours
              are 10 am to 6 pm Monday through Saturday, and we attempt to
              respond to all requests within 1 business day
            </p>
            <div class="my-8" style="text-align: center">
              <button class="rec_btn_phone">
                <a href="/contact-us" class="text-white">
                  <img src="/images/rec/call.png" alt="..." /> Request a call
                  back from our team
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-light plan_list_ch">
        <div class="container" style="margin: 0 auto">
          <div class="row">
            <div class="col-lg-7 pk_bg_img3 rec_Recruitment pl-0">
              <h2>How it works, for <span>everyone</span></h2>
              <div class="row pl-4">
                <div class="col text-center rec_col_by_ch pr-3 pl-0">
                  <div class="bg-white">
                    <img src="/images/rec/directemployers.png" alt="..." />
                    <h3>
                      Direct <br />
                      employers
                    </h3>
                    <p>
                      Use powerful tools and features designed to address your
                      organisation’s recruitment needs. Support your HR function
                      to get the best employees, at lightning speed.
                    </p>
                    <button>
                      <a href="/recruiter-registeration" class="text-black">
                        Get started
                      </a>
                    </button>
                  </div>
                </div>
                <div class="col text-center rec_col_by_ch pr-3">
                  <div class="bg-white">
                    <img src="/images/rec/recruitmentagencies.png" alt="..." />
                    <h3 class="pt-1">Recruitment agencies</h3>
                    <p>
                      Getting hold of the best talent at lightning speed, and
                      presenting it quickly to your clients is the only way to
                      stay competitive. Let <a href="/"> job4u.pk</a>, help you
                      with that.
                    </p>
                    <button>
                      <a href="/recruiter-registeration" class="text-black">
                        Get started
                      </a>
                    </button>
                  </div>
                </div>
                <div class="col text-center rec_col_by_ch rec_col_by_ch2">
                  <div class="bg-white">
                    <img src="/images/rec/assistedrecruitment.png" alt="..." />
                    <h3>Assisted recruitment</h3>
                    <p>
                      Are you looking for IT & software engineering top talent?
                      Do you need fully managed resources to augment your
                      current IT function or require dedicated teams? Look no
                      more! Contact us now
                    </p>
                    <button>
                      <a href="/contact-us" class="text-black"> Get started </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5 rec_form text-center rec_form_style by_ch_nmb">
              <div class="ml-5 p-5" style="background: #005595">
                <div
                  class="login-form login-signup"
                  :class="{
                    'login-signin-on': this.state == 'signin',
                    'login-signup-on': this.state == 'signup',
                    'login-forgot-on': this.state == 'forgot',
                  }"
                  id="kt_login"
                >
                  <form
                    class="form text-center"
                    novalidate="novalidate"
                    id="kt_login_signup_form"
                    @submit.stop.prevent="onSubmitRegister()"
                  >
                    <div class="pb-2 pt-lg-0 pt-5">
                      <h2 class="auth_title">Create an account</h2>
                    </div>

                    <div class="col_by_ch_plan_r">
                      <div class="form-check form-check-inline text-muted">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="direct_recruitment"
                          selected="true"
                          id="direct_employer"
                          @change="
                            () => {
                              recruiterSignUp = true;
                              agencySignUp = false;
                              showForm('signup');
                            }
                          "
                        />
                        <label
                          class="form-check-label text-white pt-1"
                          for="direct_employer"
                        >
                          Direct employer
                        </label>
                      </div>
                      <div class="form-check form-check-inline mb-5 d-none">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="direct_recruitment"
                          id="recruitment_agency"
                          @change="
                            () => {
                              agencySignUp = true;
                              recruiterSignUp = false;
                              showForm('signup');
                            }
                          "
                        />
                        <label
                          class="form-check-label text-white pt-1"
                          for="recruitment_agency"
                        >
                          Recruitment agency
                        </label>
                      </div>
                    </div>
                    <div class="form-group auth_inputs_img position-relative">
                      <img
                        src="/images/login_contact/usernameemail.svg"
                        alt=""
                      />
                      <input
                        class="form-control fieldch"
                        type="text"
                        placeholder="First name"
                        name="firstName"
                        ref="firstName"
                        autocomplete="off"
                      />
                    </div>
                    <div class="form-group auth_inputs_img position-relative">
                      <img
                        src="/images/login_contact/usernameemail.svg"
                        alt=""
                      />
                      <input
                        class="form-control fieldch"
                        type="text"
                        placeholder="Last name"
                        name="lastName"
                        ref="lastName"
                        autocomplete="off"
                      />
                    </div>
                    <div
                      v-if="recruiterSignUp"
                      class="form-group auth_inputs_img position-relative"
                    >
                      <img src="/images/login_contact/co.svg" alt="" />
                      <input
                        class="form-control fieldch"
                        type="text"
                        placeholder="Company name"
                        name="compnay_name"
                        ref="compnay_name"
                        autocomplete="off"
                      />
                    </div>
                    <div
                      v-if="agencySignUp"
                      class="form-group auth_inputs_img position-relative"
                    >
                      <img src="/images/login_contact/co.svg" alt="" />
                      <input
                        class="form-control fieldch"
                        type="text"
                        placeholder="Company name"
                        name="compnay_name"
                        ref="compnay_name"
                        autocomplete="off"
                      />
                    </div>
                    <div class="form-group auth_inputs_img position-relative">
                      <img src="/images/login_contact/email.svg" alt="" />
                      <input
                        class="form-control fieldch"
                        type="email"
                        placeholder="Email Address"
                        name="email"
                        ref="remail"
                        autocomplete="off"
                      />
                    </div>
                    <div class="form-group auth_inputs_img position-relative">
                      <img src="/images/login_contact/password.svg" alt="" />
                      <input
                        class="form-control fieldch"
                        type="password"
                        placeholder="Enter Password"
                        name="password"
                        ref="rpassword"
                        autocomplete="off"
                      />
                    </div>
                    <!-- <div class="form-group auth_inputs_img position-relative">
                      <img src="/images/login_contact/password.svg" alt="" />
                      <input
                        class="form-control fieldch"
                        type="password"
                        placeholder="Retype Password"
                        name="cpassword"
                        ref="cpassword"
                        autocomplete="off"
                      />
                    </div> -->
                    <div class="form-group mb-0">
                      <label
                        class="checkbox mb-0"
                        style="color: #3f4254 !important"
                      >
                        <p class="ml-2">
                          <input
                            class="mr-1 align-middle"
                            type="checkbox"
                            name="agree"
                          />
                          I have read and accept the
                          <a
                            href="/terms-and-conditions"
                            class="ml-1 text-primary font-weight-bolder"
                            >terms and conditions</a
                          >
                        </p>
                      </label>
                    </div>
                    <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                      <!-- @click="onSubmitRegister()" -->
                      <button
                        ref="kt_login_signup_submit"
                        class="btn submitBnt"
                        style="width: 150px"
                      >
                        SIGN UP
                      </button>
                    </div>
                  </form>
                </div>
                <div class="hr_blue"></div>
                <div
                  class="text-white text-center"
                  style="font-size: 13px !important"
                >
                  Already a member?
                  <a id="" class="text-primary font-weight-bolder" href="/login"
                    >Login here</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-light mt-15">
        <div class="container" style="margin: 0 auto">
          <div class="row">
            <div class="col-lg-7 pl-0">
              <div class="row">
                <div class="col-lg-12 rec_bg_last_img">
                  <h2>
                    We will help you find the candidates that are right for your
                    job
                  </h2>
                  <p>
                    Thousands of job seekers are looking for opportunities,
                    meeting your job specs. Let
                    <a href="/" class="text-white">job4u.pk</a> do the
                    match-making
                  </p>
                  <button v-if="auth || recruiter">
                    <a href="/recruiter/jobs/create" class="">Post a job</a>
                  </button>
                  <button v-if="!auth">
                    <a href="/login" class="">Post a job</a>
                  </button>
                  <button v-if="auth || recruiter">
                    <a href="/recruiter/cv-search" class="">Search for CV</a>
                  </button>
                  <button v-if="!auth">
                    <a href="/login" class="">Search for CV</a>
                  </button>
                </div>
                <!--  -->
                <div class="col-lg-12 bg-white mt-15 hide_on_dectop_ch">
                  <div class="container bg-white p-0 my-7">
                    <div class="row">
                      <div class="col-lg-12 pb-7 px-0">
                        <div class="greeen_heading">
                          Companies looking for top talent
                        </div>
                      </div>
                      <div class="col row pr-0">
                        <div class="col-lg-2 col-4 p-0">
                          <img
                            class="img_w_h"
                            src="/images/home/logos_hiring/01.svg"
                            alt="..."
                          />
                        </div>
                        <div class="col-lg-2 col-4 p-0">
                          <img
                            class="img_w_h"
                            src="/images/home/logos_hiring/02.svg"
                            alt="..."
                          />
                        </div>
                        <div class="col-lg-2 col-4 p-0">
                          <img
                            class="img_w_h"
                            src="/images/home/logos_hiring/03.svg"
                            alt="..."
                          />
                        </div>
                        <div class="col-lg-2 col-4 p-0">
                          <img
                            class="img_w_h"
                            src="/images/home/logos_hiring/04.svg"
                            alt="..."
                          />
                        </div>
                        <div class="col-lg-2 col-4 p-0">
                          <img
                            class="img_w_h"
                            src="/images/home/logos_hiring/05.svg"
                            alt="..."
                          />
                        </div>
                        <div class="col-lg-2 col-4 p-0">
                          <img
                            class="img_w_h"
                            src="/images/home/logos_hiring/06.svg"
                            alt="..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 mt-15 hide_on_mobile_ch py-4 bg-white">
                  <div class="row">
                    <div class="col row pr-0">
                      <div class="col-lg-4 col-4 p-0">
                        <img
                          class="img_w_h"
                          src="/images/home/logos_hiring/01.svg"
                          alt="..."
                        />
                      </div>
                      <div class="col-lg-4 col-4 p-0">
                        <img
                          class="img_w_h"
                          src="/images/home/logos_hiring/02.svg"
                          alt="..."
                        />
                      </div>
                      <div class="col-lg-4 col-4 p-0">
                        <img
                          class="img_w_h"
                          src="/images/home/logos_hiring/03.svg"
                          alt="..."
                        />
                      </div>
                      <div class="col-lg-4 mt-4 col-4 p-0">
                        <img
                          class="img_w_h"
                          src="/images/home/logos_hiring/04.svg"
                          alt="..."
                        />
                      </div>
                      <div class="col-lg-4 mt-4 col-4 p-0">
                        <img
                          class="img_w_h"
                          src="/images/home/logos_hiring/05.svg"
                          alt="..."
                        />
                      </div>
                      <div class="col-lg-4 mt-4 col-4 p-0">
                        <img
                          class="img_w_h"
                          src="/images/home/logos_hiring/06.svg"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--  -->
              </div>
            </div>
            <div class="col-lg-5 rec_form text-center hide_on_mobile_ch">
              <div class="ml-5 p-5 bg-white">
                <div class="login-form login-signup">
                  <form
                    v-on:submit="sendForm"
                    class="form text-center px-10 py-1"
                  >
                    <div class="pb-5 pt-lg-0 pt-5">
                      <h2 class="auth_title">Send a Question</h2>
                    </div>
                    <div class="form-group auth_inputs_img position-relative">
                      <img
                        src="/images/login_contact/usernameemail.svg"
                        alt=""
                      />
                      <input
                        class="form-control fieldch"
                        type="text"
                        v-model="ContactForm.name_mail"
                        placeholder="Your name"
                        name="name_mail"
                        autocomplete="off"
                      />
                    </div>
                    <div class="form-group auth_inputs_img position-relative">
                      <img src="/images/login_contact/email.svg" alt="" />
                      <input
                        class="form-control fieldch"
                        type="email"
                        v-model="ContactForm.email_mail"
                        placeholder="Email Address"
                        name="email_mail"
                        autocomplete="off"
                      />
                    </div>
                    <div class="form-group auth_inputs_img position-relative">
                      <textarea
                        class="form-control fieldch"
                        name="msg_mail"
                        v-model="ContactForm.msg_mail"
                        placeholder="Your question"
                      >
                      </textarea>
                    </div>
                    <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                      <button class="btn submitBnt" style="width: 150px">
                        SEND
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--end section-->
    <!-- Hero End -->

    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
  